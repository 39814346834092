import { useCallback, useMemo, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Link, usePush } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import HoverTip from 'bloko/blocks/drop/Tip/HoverTip';
import FormSpacer from 'bloko/blocks/form/FormSpacer';
import Gap from 'bloko/blocks/gap';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import Text, { TextSize } from 'bloko/blocks/text';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { createCart } from 'src/api/price/cart';
import FormatMoney from 'src/components/FormatMoney';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';
import fetcher from 'src/utils/fetcher';

const contentTypes = {
    ZARPLATA: 'zarplata',
    DEFAULT: 'default',
};

const ControlContentEditMode = ({
    saveConstructor,
    hasSave,
    employerId,
    trls,
    toggleEditMode,
    saveInProgress,
    hasService,
    selectRegionProps: { selectedRegions, currency, cost, hasPrice, selectRegionElement },
}) => {
    const contentRef = useRef();
    const { addNotification } = useNotification();
    const push = usePush();
    const readyToActivateCartId = useSelector(({ employerConstructor }) => employerConstructor.readyToActivateCartId);
    const isZP = useIsZarplataPlatform();

    const activateCart = useCallback(async () => {
        try {
            const response = await fetcher.post('/shards/employer/carts/validate', null, {
                params: { cartId: readyToActivateCartId, employerId },
            });
            push(response.data.redirectUrl);
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
        }
    }, [readyToActivateCartId, employerId, push, addNotification]);

    const activateButtonElement = useMemo(
        () => (
            <Button
                data-qa="cart-item__button-activate"
                name="employer_constructor_activate"
                onClick={activateCart}
                kind={ButtonKind.Success}
            >
                {trls[ControlContentEditMode.trls.activate]}
            </Button>
        ),
        [activateCart, trls]
    );

    const handleClickCreateCart = useCallback(() => {
        createCart(selectedRegions, addNotification, push);
    }, [addNotification, push, selectedRegions]);

    const buyButtonElement = useMemo(
        () =>
            readyToActivateCartId
                ? activateButtonElement
                : hasPrice && (
                      <Button
                          data-qa="cart-item__button-buy"
                          name="employer_constructor_buy"
                          {...(hasService ? { appearance: ButtonAppearance.Outlined } : { kind: ButtonKind.Success })}
                          onClick={handleClickCreateCart}
                      >
                          {hasService
                              ? trls[ControlContentEditMode.trls.prolongation]
                              : trls[ControlContentEditMode.trls.buyFor]}
                          {NON_BREAKING_SPACE}
                          <FormatMoney currency={currency}>{cost}</FormatMoney>
                      </Button>
                  ),
        [
            activateButtonElement,
            cost,
            currency,
            hasService,
            handleClickCreateCart,
            hasPrice,
            readyToActivateCartId,
            trls,
        ]
    );

    const saveButton = (
        <Button
            data-qa="employer-constructor-save-button"
            disabled={!hasSave}
            onClick={saveConstructor}
            kind={ButtonKind.Primary}
            loading={saveInProgress && <Loading scale={LoadingScale.Small} />}
        >
            {hasSave ? trls[ControlContentEditMode.trls.save] : trls[ControlContentEditMode.trls.saved]}
        </Button>
    );

    const contentType = isZP ? contentTypes.ZARPLATA : contentTypes.DEFAULT;

    return (
        <div
            ref={contentRef}
            className={classnames(
                'employer-constructor-controls__content',
                `employer-constructor-controls__content-edit-${contentType}`
            )}
        >
            {hasPrice && (
                <div className="employer-constructor-controls-buy-form">
                    {!readyToActivateCartId && (
                        <div className="employer-constructor-controls__region">
                            <Text size={TextSize.Large} strong>
                                {trls[ControlContentEditMode.trls.regionTitle]}
                            </Text>
                            {selectRegionElement}
                        </div>
                    )}
                    <Gap left>
                        {hasService ? (
                            <HoverTip
                                host={contentRef.current}
                                render={() => trls[ControlContentEditMode.trls.cautionHint]}
                            >
                                <div>{buyButtonElement}</div>
                            </HoverTip>
                        ) : (
                            buyButtonElement
                        )}
                    </Gap>
                </div>
            )}
            <div className="employer-constructor-xs-hidden">
                <Button
                    data-qa="employer-constructor-cancel-button"
                    disabled={!hasSave}
                    Element={Link}
                    to="/employer/constructor"
                >
                    {trls[ControlContentEditMode.trls.cancel]}
                </Button>
                <FormSpacer>
                    {hasSave ? (
                        saveButton
                    ) : (
                        <HoverTip host={contentRef.current} render={() => trls[ControlContentEditMode.trls.savedTip]}>
                            <div>{saveButton}</div>
                        </HoverTip>
                    )}
                </FormSpacer>
                {!isZP && (
                    <FormSpacer>
                        <Button
                            data-qa="employer-constructor-preview-button"
                            disabled={false}
                            onClick={toggleEditMode}
                            appearance={ButtonAppearance.Outlined}
                        >
                            {trls[ControlContentEditMode.trls.preview]}
                        </Button>
                    </FormSpacer>
                )}
            </div>
        </div>
    );
};

ControlContentEditMode.trls = {
    goToPreview: 'employer.constructor.controls.goToPreview',
    goToConstructor: 'employer.constructor.controls.goToConstructor',
    cancel: 'employer.constructor.controls.cancel',
    save: 'employer.constructor.controls.save',
    saved: 'employer.constructor.controls.saved',
    savedTip: 'employer.constructor.controls.saved.tip',
    buy: 'employer.constructor.controls.buy',
    buyFor: 'employer.constructor.controls.buyFor',
    prolongation: 'employer.constructor.controls.prolongation',
    cautionHint: 'price.branding.regionselect.cautionHint',
    regionTitle: 'employer.constructor.controls.regionTitle',
    editModeDisabled: 'employer.constructor.controls.editModeDisabled',
    preview: 'employer.constructor.controls.preview',
    activate: 'employer.constructor.controls.activate',
};

ControlContentEditMode.propTypes = {
    saveConstructor: PropTypes.func,
    hasSave: PropTypes.bool,
    trls: PropTypes.object,
    toggleEditMode: PropTypes.func,
    saveInProgress: PropTypes.bool,
    employerId: PropTypes.string,
    hasService: PropTypes.bool,
    selectRegionProps: PropTypes.shape({
        selectedRegions: PropTypes.array,
        currency: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        cost: PropTypes.number,
        hasPrice: PropTypes.bool,
        selectRegionElement: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    }),
};

export default translation(ControlContentEditMode);
