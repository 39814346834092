import { Fragment } from 'react';

import { VSpacingContainer } from '@hh.ru/magritte-ui';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FullPageLayout from 'src/app/layouts/FullPageLayout';
import ContainerForXSL from 'src/components/CodeInjector/ContainerForXSL';
import Portal from 'src/components/CodeInjector/Portal';
import ChameleonHeader from 'src/components/Employer/ChameleonHeader';
import { usePageTabsContext } from 'src/components/Employer/PageTabs/PageTabsContext';
import Subscribe from 'src/components/Employer/Sidebar/Subscribe';
import SubscribeProvider from 'src/components/Employer/Subscribe/SubscribeProvider';
import VacanciesFilter from 'src/components/Employer/VacanciesFilter';
import VacancySearch from 'src/components/Employer/VacancySearch';
import VacanciesLink from 'src/components/Employer/VacancySearch/VacanciesLink';
import EmployerReviewsBigWidget from 'src/components/EmployerReviews/WidgetBig';
import RowContent from 'src/components/RowContent';
import WantSame from 'src/components/VacancyView/WantSame';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    subscribteText: 'employer.view.vacancy.autosearch.button',
};

const EmployerChameleon: TranslatedComponent = ({ trls }) => {
    const abortPageContent = useSelector((state) => state.abortPageContent);
    const employerId = useSelector((state) => state.employerInfo?.id);
    const disableChameleonHeader = useSelector((state) => state.disableChameleonHeader);
    const hideDefaultSubscribe = useSelector((state) => state.hideDefaultSubscribe);
    const isShowEmployerVacancySearch = useSelector(({ showEmployerVacancySearch }) => showEmployerVacancySearch);
    const { isEmployerTabsExp, isMobileView } = usePageTabsContext();
    const isDefaultSubscribeStretched = isMobileView && isShowEmployerVacancySearch;
    const isDefaultSubscribe = !hideDefaultSubscribe && !isDefaultSubscribeStretched;

    const ContentWrapper = disableChameleonHeader ? Fragment : RowContent;

    return (
        <FullPageLayout>
            <ContentWrapper>
                {!disableChameleonHeader && <ChameleonHeader />}
                <ContainerForXSL place="legacy-page-layout-xsl" />
                <WantSame keyPrefix="employerId" id={String(employerId)} />
                <SubscribeProvider>
                    {!abortPageContent && (
                        <Portal place="vacancies-react-content">
                            {isEmployerTabsExp && isMobileView ? <VacanciesLink /> : <VacanciesFilter />}
                        </Portal>
                    )}
                    <ColumnsWrapper>
                        <Column l="16" m="12" s="8" xs="4">
                            {isShowEmployerVacancySearch && (
                                <VSpacingContainer default={8}>
                                    <VacancySearch hasChameleon />
                                    {isDefaultSubscribeStretched && (
                                        <Subscribe
                                            subscribeButtonText={trls[TrlKeys.subscribteText]}
                                            stretched
                                            size="medium"
                                        />
                                    )}
                                </VSpacingContainer>
                            )}
                            {isDefaultSubscribe && (
                                <div className="employer-branded-savedsearch">
                                    <Subscribe subscribeButtonText={trls[TrlKeys.subscribteText]} />
                                </div>
                            )}
                            <EmployerReviewsBigWidget hasWrapper={false} />
                        </Column>
                    </ColumnsWrapper>
                </SubscribeProvider>
            </ContentWrapper>
        </FullPageLayout>
    );
};

export default translation(EmployerChameleon);
